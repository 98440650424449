import React from 'react';
import PropTypes from 'prop-types';
import mimeTypes from 'mime-types';

import previewFileTypes from './utils/previewFileTypes';
import getFileType from './utils/getFileType';

const Preview = ({ source, file, ...restProps }) => {
  const mimeType = mimeTypes.lookup(file.name);
  switch (getFileType(mimeType)) {
    case previewFileTypes.AUDIO:
      return (
        <audio controls {...restProps}>
          <source src={source} type={mimeType} />
        </audio>
      );
    case previewFileTypes.IMAGE:
      return <img alt={file.name} src={source} {...restProps} />;
    case previewFileTypes.VIDEO:
      return (
        <video controls {...restProps}>
          <source src={source} type={mimeType} />
        </video>
      );
    // TODO
    default:
      return 'file';
  }
};

Preview.propTypes = {
  source: PropTypes.string.isRequired,
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default Preview;
