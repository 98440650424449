export const register = (axiosInstance, authToken, config = {}) => {
  const { shouldExcludeUrl } = config;

  const interceptor = axiosInstance.interceptors.request.use((config) => {
    if (shouldExcludeUrl && shouldExcludeUrl(config.url)) {
      return config;
    }

    const configWithAuth = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
      },
    };

    return configWithAuth;
  });

  return interceptor;
};

export const unregister = (axiosInstance, interceptor) =>
  axiosInstance.interceptors.request.eject(interceptor);
