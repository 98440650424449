import { useState, useCallback } from 'react';

const useDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => setOpen(false), []);

  return { open, onClose: handleClose, setOpen };
};

export default useDialog;
