import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FilesInput from '../FilesInput';

const FilesField = (props) => {
  const { helperText, ...restProps } = props;

  const [{ value }, { touched, error }, { setValue }] = useField(props);

  const showError = touched && !!error;

  return (
    <FilesInput
      value={value}
      onChange={setValue}
      error={showError}
      helperText={showError ? error : helperText}
      {...restProps}
    />
  );
};

FilesField.propTypes = {
  helperText: PropTypes.string,
};

export default FilesField;
