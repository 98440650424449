import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import ButtonFilePicker from '../ButtonFilePicker';

const useStyles = makeStyles((theme) => ({
  labelWrapper: {
    position: 'relative',
  },
}));

const FilesInput = ({
  label,
  disabled,
  error,
  multiple,
  fullWidth,
  helperText,
  value,
  onChange,
  renderValue,
  ...restProps
}) => {
  const classes = useStyles();

  const handleSelect = (files) => {
    if (files.length > 0) {
      let newValue = multiple ? [...value, ...files] : [files[0]];
      onChange(newValue);
    }
  };

  const removeFile = (index) =>
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth}>
      {label && (
        <>
          <InputLabel>{label}</InputLabel>
          {/* NOTE this is required for the label to be displayed. it must have
          a value so that label is displayed above it, not inside it, but it
          must not be visible, so dimensions are 0 */}
          <Input value="dummy" type="hidden" style={{ width: 0, height: 0 }} />
        </>
      )}
      {renderValue({ value, utils: { removeFile } })}
      <ButtonFilePicker
        disabled={disabled}
        multiple={multiple}
        ButtonSlimProps={{ startIcon: <AddIcon /> }}
        onSelect={handleSelect}
        {...restProps}
      >
        Pridėti
      </ButtonFilePicker>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

FilesInput.defaultProps = {
  disabled: false,
  error: false,
  multiple: false,
  fullWidth: false,
};

FilesInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired,
};

export default FilesInput;
