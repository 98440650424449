import { useEffect, useState } from 'react';

import { register, unregister } from './utils/axiosReqAuthIntcptUtils';

const useAxiosReqAuthIntcpt = (axiosInstance, authToken, config) => {
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (authToken) {
      const interceptor = register(axiosInstance, authToken, config);
      setRegistered(true);

      return () => {
        unregister(axiosInstance, interceptor);
        setRegistered(false);
      };
    }
  }, [axiosInstance, authToken, config]);

  return registered;
};

export default useAxiosReqAuthIntcpt;
