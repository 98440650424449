import React from 'react';
import { Field } from 'formik';
import { fieldToCheckbox } from 'formik-material-ui';

import CheckboxInput from '../CheckboxInput';

const Component = (props) => <CheckboxInput {...fieldToCheckbox(props)} />;

const CheckboxField = (props) => (
  <Field component={Component} type="checkbox" {...props} />
);

export default CheckboxField;
