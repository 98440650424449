import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'web-app-components/components/TextInput';
import TextField from 'web-app-components/components/TextField';
import { useField } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import calculateGoodTotalPrice from '../../utils/calculateGoodTotalPrice';
import calculateGoodTotalPiguPrice from '../../utils/calculateGoodTotalPiguPrice';
import calculateGoodBasePriceEur from '../../utils/calculateGoodBasePriceEur';

const useStyles = makeStyles((theme) => ({
  basePrice: {
    display: 'flex',
    marginBottom: theme.spacing(2),

    '& > *': {
      flex: 1,
    },

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  columns: {
    display: 'flex',
  },
  inputsColumn: {
    flex: 1,

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  totalPricesColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  price: {
    fontWeight: 'bold',
  },
}));

const PriceField = (props) => {
  const { displayPigu } = props;

  const classes = useStyles();

  const [{ value }] = useField(props);

  const totalPrice = calculateGoodTotalPrice(value);
  const totalPiguPrice = calculateGoodTotalPiguPrice(value);

  const makeFieldName = (name) => `${props.name}.${name}`;

  return (
    <div>
      <div className={classes.basePrice}>
        <TextField
          type="number"
          name={makeFieldName('base')}
          label="Savikaina tiekėjo valiuta"
        />
        <TextField
          type="number"
          name={makeFieldName('baseCurrencyRate')}
          label="Tiekėjo valiutos kursas"
          helperText="Jei tiekėjo valiuta yra EUR, palikti laukelį tuščią arba įvesti 1"
        />
        <TextInput
          type="number"
          label="Savikaina, EUR"
          value={calculateGoodBasePriceEur(value)}
          disabled
        />
      </div>
      <div className={classes.columns}>
        <div className={classes.inputsColumn}>
          <TextField
            type="number"
            name={makeFieldName('profit')}
            label="Pelnas, %"
            fullWidth
          />
          {displayPigu && (
            <TextField
              type="number"
              name={makeFieldName('piguCharge')}
              label="pigu.lt antkainis, %"
              fullWidth
            />
          )}
          <TextField
            type="number"
            name={makeFieldName('delivery')}
            label="Transportas, EUR"
            fullWidth
          />
          <TextField
            type="number"
            name={makeFieldName('vat')}
            label="PVM, %"
            fullWidth
          />
        </div>
        <div className={classes.totalPricesColumn}>
          <Typography>
            E-parduotuvės kaina:{' '}
            <span className={classes.price}>{totalPrice} EUR</span>
          </Typography>
          {displayPigu && (
            <Typography>
              pigu.lt kaina:{' '}
              <span className={classes.price}>{totalPiguPrice} EUR</span>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

PriceField.defaultProps = {
  displayPigu: false,
};

PriceField.propTypes = {
  name: PropTypes.string.isRequired,
  displayPigu: PropTypes.bool,
};

export default PriceField;
