import React from 'react';
import { Field } from 'formik';
import { fieldToTextField } from 'formik-material-ui';

import TextInput from '../TextInput';

const Component = (props) => {
  // NOTE for some reason fieldToTextField returns "variant: undefined" if no
  // variant prop is passed to Field, which clears TextInput's variant value,
  // which is not good for us, so remove variant prop if it's not set to any
  // meaningful value
  const { variant, ...textFieldProps } = fieldToTextField(props);
  if (variant) {
    textFieldProps.variant = variant;
  }

  return <TextInput {...textFieldProps} />;
};

const TextField = (props) => <Field component={Component} {...props} />;

export default TextField;
