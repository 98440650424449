import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,

    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const ListItemLink = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <ListItem
      component={NavLink}
      activeClassName={classes.active}
      button
      {...restProps}
    >
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ListItemLink;
