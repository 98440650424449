import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form } from 'formik';

import ButtonPrimary from '../ButtonPrimary';
import TextField from '../TextField';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const SignInForm = (props) => {
  const classes = useStyles();

  const initialValues = { username: '', password: '' };

  return (
    <Formik initialValues={initialValues} {...props}>
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <TextField
            className={classes.field}
            type="text"
            name="username"
            label="Prisijungimo vardas"
          />
          <TextField
            className={classes.field}
            type="password"
            name="password"
            label="Slaptažodis"
          />
          <ButtonPrimary type="submit" disabled={isSubmitting}>
            Prisijungti
          </ButtonPrimary>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
