const makeRestClient = (axiosInstance, endpointUrl) => ({
  async find(query) {
    const { data } = await axiosInstance.get(endpointUrl, {
      params: { _limit: -1, ...query },
    });
    return data;
  },

  async count(query) {
    const { data } = await axiosInstance.get(`${endpointUrl}/count`, {
      params: query,
    });
    return data;
  },

  async findOne(id) {
    const { data } = await axiosInstance.get(`${endpointUrl}/${id}`);
    return data;
  },

  async create(data) {
    const { data: resultData } = await axiosInstance.post(endpointUrl, data);
    return resultData;
  },

  async update(id, data) {
    const { data: resultData } = await axiosInstance.put(
      `${endpointUrl}/${id}`,
      data
    );
    return resultData;
  },
});

export default makeRestClient;
