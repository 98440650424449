import { useState, useCallback } from 'react';

const useAppDrawer = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleClose = useCallback(() => setMobileOpen(false), [setMobileOpen]);

  return { mobileOpen, setMobileOpen, onClose: handleClose };
};

export default useAppDrawer;
