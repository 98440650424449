import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUIAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import appDrawerWidth from '../../utils/appDrawerWidth';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: ({ enableMenu }) =>
        enableMenu && `calc(100% - ${appDrawerWidth}px)`,
      marginLeft: ({ enableMenu }) => enableMenu && appDrawerWidth,
    },
  },
  toolbar: {
    display: 'flex',
  },
  text: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const AppBar = ({ text, actions, enableMenu, onMenuClick }) => {
  const classes = useStyles({ enableMenu });

  return (
    <MUIAppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        {enableMenu && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onMenuClick}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography className={classes.text} variant="h6" noWrap>
          {text}
        </Typography>
        {actions}
      </Toolbar>
    </MUIAppBar>
  );
};

AppBar.defaultProps = {
  enableMenu: false,
  onMenuClick: () => {},
};

AppBar.propTypes = {
  text: PropTypes.string.isRequired,
  actions: PropTypes.node,
  enableMenu: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

export default AppBar;
