import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import appDrawerWidth from '../../utils/appDrawerWidth';

import useAppDrawer from './hooks/useAppDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: appDrawerWidth,
      flexShrink: 0,
    },
  },
  // NOTE align drawer content with app content that is below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: appDrawerWidth,
  },
}));

const AppDrawer = ({ mobileOpen, onClose, children }) => {
  const classes = useStyles();

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {children}
    </div>
  );

  return (
    <nav className={classes.root}>
      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            // NOTE better open performance on mobile
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

AppDrawer.defaultProps = {
  mobileOpen: false,
  onDrawerClose: () => {},
};

AppDrawer.propTypes = {
  mobileOpen: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  children: PropTypes.node,
};

export default AppDrawer;

export { useAppDrawer };
