import calculateGoodBasePriceEur from './calculateGoodBasePriceEur';
import pcntToCof from './pcntToCof';

const calculateGoodTotalPrice = ({
  base,
  baseCurrencyRate,
  delivery,
  vat,
  profit,
}) => {
  const _base = calculateGoodBasePriceEur({ base, baseCurrencyRate });
  const _delivery = delivery || 0;
  const _vat = pcntToCof(vat || 0);
  const _profit = pcntToCof(profit || 0);

  const _eShopPrice = _base * _profit + _delivery;

  // NOTE if final price is less than 0.5 before rounding, then it's rounded to
  // 0, which is nonsense, so in such case make it 1
  return Math.round(_eShopPrice * _vat) || 1;
};

export default calculateGoodTotalPrice;
