import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

const SelectInput = ({ className, variant, ...props }) => {
  props.variant = variant || 'outlined';

  return (
    <FormControl
      className={className}
      variant={props.variant}
      fullWidth={props.fullWidth}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} />
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
