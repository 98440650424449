import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';

import MultiInput from '../MultiInput';

const MultiField = ({ name, renderAddButton, ...componentProps }) => (
  <FieldArray name={name}>
    {({ form: { values, isSubmitting }, push, remove }) => (
      <MultiInput
        values={getIn(values, name)}
        push={push}
        remove={remove}
        disabled={isSubmitting}
        renderAddButton={
          renderAddButton &&
          (() => renderAddButton({ values, push, remove, isSubmitting }))
        }
        {...componentProps}
      />
    )}
  </FieldArray>
);

MultiField.propTypes = {
  name: PropTypes.string.isRequired,
  renderAddButton: PropTypes.func,
};

export default MultiField;
