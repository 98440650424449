import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import isAuthTokenValid from './utils/isAuthTokenValid';

const AUTH_STATE_LOCAL_STORAGE_KEY = 'auth_state';

const defaultAuthValue = {
  haveAuthState: false,
  tokenValid: false,

  state: { token: undefined },

  signIn: (token) => {},
  signOut: () => {},
};
const AuthContext = createContext(defaultAuthValue);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    ...defaultAuthValue,

    signIn: (token) => setAuthState({ token }),

    signOut: () => setAuthState({ token: undefined }),
  });

  const setAuthState = useCallback((state) => {
    setAuth((auth) => ({
      ...auth,
      haveAuthState: true,
      tokenValid: isAuthTokenValid(state.token),
      state: { ...auth.state, ...state },
    }));
  }, []);

  useEffect(() => {
    const authState = JSON.parse(
      localStorage.getItem(AUTH_STATE_LOCAL_STORAGE_KEY)
    );
    if (authState) {
      setAuthState(authState);
    }
  }, [setAuthState]);

  useEffect(() => {
    if (auth.haveAuthState) {
      localStorage.setItem(
        AUTH_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify(auth.state)
      );
    }
  }, [auth.haveAuthState, auth.state]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
