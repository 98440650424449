import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ButtonSlim from '../ButtonSlim';

const useStyles = makeStyles({
  fileInput: {
    display: 'none',
  },
});

const ButtonFilePicker = ({
  disabled,
  ButtonSlimProps,
  onSelect,
  children,
  ...restProps
}) => {
  const classes = useStyles();

  const fileInputRef = useRef();

  const handleSelectClick = () => fileInputRef.current.click();

  const handleSelectFiles = ({ target: { files } }) => {
    onSelect([...files]);
    fileInputRef.current.value = '';
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        className={classes.fileInput}
        type="file"
        onChange={handleSelectFiles}
        {...restProps}
      />
      <ButtonSlim
        disabled={disabled}
        onClick={handleSelectClick}
        {...ButtonSlimProps}
      >
        {children}
      </ButtonSlim>
    </div>
  );
};

ButtonFilePicker.defaultProps = {
  disabled: false,
};

ButtonFilePicker.propTypes = {
  disabled: PropTypes.bool,
  ButtonSlimProps: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ButtonFilePicker;
