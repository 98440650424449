import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ButtonSlim from '../ButtonSlim';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: ({ disabled }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MultiInput = ({
  label,
  renderEntry,
  renderAddButton,
  addEntryButtonLabel,
  disabled,
  values,
  push,
  remove,
  onAdd,
}) => {
  const classes = useStyles({ disabled });

  const handleAddClick = () => onAdd({ push });

  const makeRemoveClickHandler = (index) => () => remove(index);

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.label} gutterBottom>
          {label}
        </Typography>
      )}
      {values.map((value, index) => (
        <div key={index} className={classes.inputContainer}>
          {renderEntry(value, index)}
          <IconButton
            onClick={makeRemoveClickHandler(index)}
            disabled={disabled}
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ))}
      {renderAddButton ? (
        renderAddButton()
      ) : (
        <ButtonSlim
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          disabled={disabled}
        >
          {addEntryButtonLabel}
        </ButtonSlim>
      )}
    </div>
  );
};

MultiInput.defaultProps = {
  disabled: false,
};

MultiInput.propTypes = {
  label: PropTypes.string,
  renderEntry: PropTypes.func.isRequired,
  renderAddButton: PropTypes.func,
  addEntryButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  values: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
};

export default MultiInput;
