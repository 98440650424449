import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Preview from './components/Preview';

const FilePreview = ({ file, ...restProps }) => {
  const [source, setSource] = useState();

  useEffect(() => {
    if (file instanceof File) {
      const fileObjectUrl = URL.createObjectURL(file);
      setSource(fileObjectUrl);
      return () => URL.revokeObjectURL(fileObjectUrl);
    } else {
      setSource(file.url);
    }
  }, [file]);

  return source ? <Preview source={source} file={file} {...restProps} /> : null;
};

FilePreview.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default FilePreview;
