import { useState, useEffect, useRef } from 'react';

const useStateThrottled = (initialState, throttleTime = 500) => {
  const throttleTimeRef = useRef(throttleTime);

  const [state, setState] = useState(initialState);
  const [throttledState, setThrottledState] = useState(initialState);

  useEffect(() => {
    const timeout = setTimeout(
      () => setThrottledState(state),
      throttleTimeRef.current
    );

    return () => clearTimeout(timeout);
  }, [state]);

  return [throttledState, state, setState];
};

export default useStateThrottled;
