import React from 'react';
import { Field } from 'formik';
import { fieldToSelect } from 'formik-material-ui';

import SelectInput from '../SelectInput';

const Component = (props) => <SelectInput {...fieldToSelect(props)} />;

const SelectField = (props) => <Field component={Component} {...props} />;

export default SelectField;
